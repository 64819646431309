import dayjs from 'dayjs';
var utc = require('dayjs/plugin/utc');
var customParseFormat = require('dayjs/plugin/customParseFormat');
dayjs.extend(customParseFormat);
dayjs.extend(utc);

export default {
  methods: {
    findDeviceInSummary(node, deviceId) {

      // look in site's lists
        // if it's there, look in the children
          // look in building lists
            // if it's there, look in children

      if (node.Sites) {
        let siteWithId = node.Sites.find((site) => site.DeviceIds.includes(deviceId) );
        if (siteWithId) {
          let buildingWithId = siteWithId.Buildings.find((building) => building.DeviceIds.includes(deviceId));
          if (buildingWithId) {
            let roomWithId = buildingWithId.Rooms.find((room) => room.DeviceIds.includes(deviceId));
            if (roomWithId) {
              let newNode = JSON.parse(JSON.stringify(roomWithId));
              newNode.buildingNode = buildingWithId;
              newNode.siteNode = siteWithId;
              newNode.roomNode = roomWithId;
              return newNode;
            } else {
              let newNode = JSON.parse(JSON.stringify(buildingWithId));
              newNode.buildingNode = buildingWithId;
              newNode.siteNode = siteWithId;
              return newNode;
            }
          } else {
            let newNode = JSON.parse(JSON.stringify(siteWithId));
            newNode.siteNode = siteWithId;
            return newNode;
          }
        } else {
          return null;
        }
      }

      return null;
    },

    formatTimestampWithOffset(timestamp, offset_sec) {
      const datetime = dayjs.utc(timestamp, "YY-MM-DDTHH:mm:ss[Z]", false).add(offset_sec, 'seconds').local().format('h:mma MMM D, YYYY');

      if (datetime.search("Invalid") !== -1) {
        return timestamp
      }

      return datetime;
    },

    formatTimestamp(timestamp) {
      const datetime = dayjs.utc(timestamp, "YYYY-MM-DDTHH:mm:ss[Z]", false).local().format('h:mma MMM D, YYYY');

      if (datetime.search("Invalid") !== -1) {
        return timestamp
      }

      return datetime;
    },

    formatEventTimestamp(timestamp) {
      let datetime = dayjs.utc(timestamp, "MM/DD/YYYY hh:mm:ss", false).local().format('h:mm:ssa MMM D, YYYY');
      if (datetime.search("Invalid") !== -1) {
        datetime = this.formatTimestamp(timestamp);
        if (datetime.search("Invalid") !== -1) {
          return timestamp
        }
      }

      return datetime;
    },

    formatShortEventTimestamp(timestamp) {
      const datetime = dayjs.utc(timestamp, "MM/DD/YYYY hh:mm:ss", false).local().format('h:mm:ssa');

      if (datetime.search("Invalid") !== -1) {
        return timestamp
      }

      return datetime;
    },

    formatDatePicker(date) {
      return dayjs(date).format('YYYY-MM-DD');
    },

    formatEventTime(dateString, stringFormat) {
      return dayjs.utc(dateString, stringFormat);
    },

    convertDateToUTCUnix(date) {
      return dayjs(date).utc().unix();
    },

    subtractUnitFromDate(date, amount, unit) {
      return dayjs(date).subtract(amount, unit);
    },

    addUnitToDate(date, amount, unit) {
      return dayjs(date).add(amount, unit);
    },

    today() {
      return dayjs();
    },

    yesterday() {
      return dayjs().subtract(1, 'days');
    },

    getTimestampFromBlobUrl(url) {
      // const containerName = 1; const deviceId = 2; const fileType = 3; const camera = 4; const year = 5; const month = 6; const day = 7; const hour = 8; const minute = 9; const second = 10; const fileExtention = 11;
      const fileRegex = /\/([a-zA-Z0-9'-]+)\/([\da-zA-Z]{8}-(?:[\da-zA-Z]{4}-){3}[\da-zA-Z]{12})\/(\w+)\/camera0([0-6])\/([0-9]{4})_([0-9]{2})_([0-9]{2})_([0-9]{2})_([0-9]{2})_([0-9]{2}).([a-zA-Z0-9]+)/g;
      const fileUrl = new URL(url);
      const mr = fileRegex.exec(fileUrl.pathname);
      // "MM/DD/YYYY hh:mm:ss"
      const timestamp = `${mr[6]}/${mr[7]}/${mr[5]}T${mr[8]}:${mr[9]}:${mr[10]}`;
      const formattedTimestamp = this.formatEventTimestamp(timestamp);
      return formattedTimestamp;
    },

    getShortTimestampFromBlobUrl(url) {
      // const containerName = 1; const deviceId = 2; const fileType = 3; const camera = 4; const year = 5; const month = 6; const day = 7; const hour = 8; const minute = 9; const second = 10; const fileExtention = 11;
      const fileRegex = /\/([a-zA-Z0-9'-]+)\/([\da-zA-Z]{8}-(?:[\da-zA-Z]{4}-){3}[\da-zA-Z]{12})\/(\w+)\/camera0([0-6])\/([0-9]{4})_([0-9]{2})_([0-9]{2})_([0-9]{2})_([0-9]{2})_([0-9]{2}).([a-zA-Z0-9]+)/g;
      const fileUrl = new URL(url);
      const mr = fileRegex.exec(fileUrl.pathname);
      // "MM/DD/YYYY hh:mm:ss"
      const timestamp = `${mr[6]}/${mr[7]}/${mr[5]}T${mr[8]}:${mr[9]}:${mr[10]}`;
      const formattedTimestamp = this.formatShortEventTimestamp(timestamp);
      return formattedTimestamp;
    },

    secondsToMinutes(seconds) {
      return Math.round(seconds / 60);
    },

    secondsToHours(seconds) {
      return Math.round(seconds / 3600);
    },

    secondsToDays(seconds) {
      return Math.round(seconds / 86400);
    },

    daysToSeconds(days) {
      return Math.round(days * 86400);
    },

    secondsToDayHourMinuteSecond(seconds) {
      var times = new Object();
      times['days'] = Math.floor(seconds / 86400);
      seconds %= 86400;
      times['hours'] = Math.floor(seconds / 3600);
      seconds %= 3600;
      times['minutes'] = Math.floor(seconds / 60);
      seconds %= 60;
      times['seconds'] = seconds;
      return times;
    },

    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },

    buildVersion() {
      return process.env.VUE_APP_BUILD_PIPELINE + "-" + process.env.VUE_APP_BUILD_SHA;
    },

    toUpperCaseWords(str) {
      const result = str.replace(/([A-Z])/g, " $1");
      return result.charAt(0).toUpperCase() + result.slice(1);
    },
    getOrgName(connection) {
      if (this.$store.getters['currentUserIsOwner'])
        return connection.ConsumerConnectionDetails.OrganizationName;
      else if (this.$store.getters['currentUserIsConsumer'])
        return connection.OwnerConnectionDetails.OrganizationName;
      else
        return "none";
    },
  },
};

export function convertStringtoAPIDate(dateString) {
  return dayjs(dateString, "YYYY-MM-DD").utc().toISOString();
}
