<template>
  <div v-if="banners" class="mt-4 space-y-2 banner-container">
    <div v-for="(banner, index) in banners" :key="`banner-index-${index}`">
      <div
        :class="getClassByPriority(banner.priority)"
        class="flex items-center p-4 rounded-md shadow-lg mx-4"
      >
        <div v-if="getPriorityIcon(banner.priority)" class="flex-shrink-0">
          <font-awesome-icon
            :icon="getPriorityIcon(banner.priority)"
            size="4x"
            class="text-white"
          />
        </div>
        <div class="ml-4">
          <h6 class="font-bold text-lg text-black">{{ banner.heading }}</h6>
          <p class="text-sm text-black">{{ banner.body }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';
var utc = require('dayjs/plugin/utc');
var customParseFormat = require('dayjs/plugin/customParseFormat');
dayjs.extend(customParseFormat);
dayjs.extend(utc);
export default {
  name: 'BannerComponent',

  props: {
    bannerEventList: {
      type: Array,
      required: true,
    },
  },
  computed: {
    banners() {
      return this.bannerEventList.map(this.buildBanner).filter(this.isActive).sort(this.bannerSort);
    },
  },
  methods: {
    buildBanner(bannerEvent) {
      return {
        priority: bannerEvent.other.priority,
        heading: bannerEvent.other.heading,
        body: bannerEvent.other.body,
        startTime: dayjs.utc(bannerEvent.other.startTime, 'MM/DD/YYYY hh:mm:ss', false),
        endTime: dayjs.utc(bannerEvent.other.endTime, 'MM/DD/YYYY hh:mm:ss', false),
      };
    },
    bannerSort(bannerA, bannerB) {
      const priorityOrder = {
        high: 1,
        medium: 2,
        low: 3,
        lowest: 4,
      };

      const defaultPriority = 5; // for unrecognized priorities

      const priorityA = priorityOrder[bannerA.priority] || defaultPriority;
      const priorityB = priorityOrder[bannerB.priority] || defaultPriority;

      return priorityA !== priorityB
        ? priorityA - priorityB
        : bannerA.startTime.isBefore(bannerB.startTime);
    },
    isActive(banner) {
      const currentDate = dayjs.utc();
      return currentDate.isAfter(banner.startTime) && currentDate.isBefore(banner.endTime);
    },
    getClassByPriority(priority) {
      switch (priority) {
        case 'high':
          return 'bg-red-500 text-white';
        case 'medium':
          return 'bg-yellow-500 text-white';
        case 'low':
          return 'bg-green-500 text-white';
        case 'lowest':
          return 'text-white'; // No background for lowest priority
        default:
          return 'bg-gray-500 text-white';
      }
    },
    getPriorityIcon(priority) {
      switch (priority) {
        case 'high':
          return ['far', 'exclamation-circle'];
        case 'medium':
          return ['far', 'exclamation-triangle'];
        case 'low':
          return ['fas', 'info-circle'];
        default:
          return '';
      }
    },
  },
};
</script>

<style scoped>
.banner-container {
  max-height: 420px;
  overflow-y: auto;
}
</style>
